import { graphql, PageProps, Link } from 'gatsby'
import React from 'react'
import * as paths from '../lib/paths'

import '../components/variables.css'
import '../components/global.css'
import * as styles from './contentful-validation.module.css'

type Section = {
  __typename: string
}

type ProgramPartnerPageNode = {
  contentful_id: string
  domain: string
  partnerSlug: string
  programSlug: string
  sections: Section[] | undefined
}

type DataProps = {
  allContentfulPageLanding: {
    nodes: ProgramPartnerPageNode[]
  }
}

const RootPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <div className="container">
      <h1>Contentful Validation</h1>
      {data.allContentfulPageLanding.nodes.map((page) => (
        <Entry key={page.contentful_id} page={page} />
      ))}
    </div>
  )
}

const Entry = ({ page }: { page: ProgramPartnerPageNode }) => {
  const path = paths.landingPath({
    partner: page.partnerSlug,
    program: page.programSlug,
  })

  const sections = page.sections || []

  const containsCTA = sections.find(
    (s) => s.__typename === 'ContentfulSectionCallToAction'
  )
  const containsForm = sections.find(
    (s) => s.__typename === 'ContentfulSectionForm'
  )

  if (containsForm && !containsCTA) {
    return null
  }

  return (
    <div className={styles.page}>
      <Link to={path}>{path}</Link>
      <ul className={styles.ul}>
        <li className={containsCTA ? styles.bad : styles.good}>
          CTA Section: {containsCTA ? 'Still Present' : 'OK!'}
        </li>
        <li className={containsForm ? styles.good : styles.bad}>
          Form Section: {containsForm ? 'OK!' : 'Missing'}
        </li>
      </ul>
    </div>
  )
}

export default RootPage

export const pageQuery = graphql`
  query RootContentfulValidationQuery($domain: String!) {
    allContentfulPageLanding(filter: { domain: { eq: $domain } }) {
      nodes {
        contentful_id
        domain
        partnerSlug
        programSlug
        sections {
          __typename
        }
      }
    }
  }
`
